import React, { useState } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import './css/personalized_exam.css'; // Change this to use the personalized_exam.css
import useMediaQuery from '@mui/material/useMediaQuery';
import cancelIcon from './assets/icons/cancel.png';

function ReviewExamQuestion() {
  const navigate = useNavigate();
  const location = useLocation();
  const { exam_id } = useParams();
  const isSmallScreen = useMediaQuery('(max-width: 768px)');
  const [currentContentIndex, setCurrentContentIndex] = useState(location.state?.currentContentIndex || 0);
  const examData = location.state?.examData || { questions: [] };

  const totalQuestions = examData.questions.length;
  const remainingQuestions = totalQuestions - currentContentIndex - 1;

  const handlePreviousQuestion = () => {
    if (currentContentIndex > 0) {
      setCurrentContentIndex(currentContentIndex - 1);
    }
  };

  const handleNextQuestion = () => {
    if (currentContentIndex < totalQuestions - 1) {
      setCurrentContentIndex(currentContentIndex + 1);
    } else {
      navigate(`/review-exam/${exam_id}`);
    }
  };

  const handleBackButtonClick = () => {
    navigate(`/review-exam/${exam_id}`);
  };

  if (examData.questions.length === 0 || !examData.questions[currentContentIndex]) {
    return <div>No content available</div>;
  }

  const currentQuestion = examData.questions[currentContentIndex];
  const { script, question_text, instruction, choices, correct_answer, selected_answer, explanation } = currentQuestion;

  return (
    <div className="Exam">
      <div className={`upper-bar`}>
        <div className="upper-left">
          <div onClick={handleBackButtonClick} aria-label="cancel" className="cancel-button-exam">
            <img src={cancelIcon} alt="Cancel" />
          </div>
          <div className="questions-remaining">
            {remainingQuestions} questions remaining
          </div>
        </div>
      </div>

      <div className="content">
        {isSmallScreen ? (
          <div className="right-section">
            <div className='question-title'> Question {currentContentIndex + 1}</div>
            {instruction && <p>{instruction}</p>}
            <br />
            {script && script !== "0" && (
              <div className="passage-box">
                {script}
              </div>
            )}
            <br />
            <div className="question-answer">
              <div className="question-text">{question_text}</div>
              <div className="answer-section">
                {choices.choices.map((choice, index) => (
                  <div
                    key={index}
                    className={`answer-option 
                      ${selected_answer === choice ? 'selected' : ''} 
                      ${(index + 1).toString() === correct_answer ? 'correct' : (selected_answer === choice ? 'incorrect' : '')}`}
                  >
                    {choice}
                  </div>
                ))}
              </div>
              {explanation && (
                <div className="explanation-box">
                  <strong>Explanation:</strong> {explanation}
                </div>
              )}
            </div>
          </div>
        ) : (
          <div className="personalized-page">
            <div className='question-title'> Question {currentContentIndex + 1}</div>
            {instruction && <p>{instruction}</p>}
            {script && script !== "0" ? (
              <div className="passage-box">
                {script}
              </div>
            ) : (
              <hr className="question-divider" />
            )}
            <div className="question-answer">
              <div className="question-text">{question_text}</div>
              <div className="answer-section">
                {choices.choices.map((choice, index) => (
                  <div
                    key={index}
                    className={`answer-option 
                      ${selected_answer === choice ? 'selected' : ''} 
                      ${(index + 1).toString() === correct_answer ? 'correct' : (selected_answer === choice ? 'incorrect' : '')}`}
                  >
                    {choice}
                  </div>
                ))}
              </div>
              {explanation && (
                <div className="explanation-box">
                  <strong>Explanation:</strong> {explanation}
                </div>
              )}
            </div>
          </div>
        )}
      </div>

      <div className={`lower-bar`}>
        <div className="lower-left"></div>
        <div className="lower-right">
          <button className="previous-button" onClick={handlePreviousQuestion}>Previous</button>
          <button className="reveal-answer-button" onClick={handleNextQuestion}>Next</button>
        </div>
      </div>
    </div>
  );
}

export default ReviewExamQuestion;