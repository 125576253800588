import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import './css/Home.css';
// import Dropdown from './components/Dropdown';
// import SectionDropdown from './SectionDropdown';
import './css/ExamBox.css';  // Add this line
import BeatLoader from 'react-spinners/BeatLoader';
import Logo from './assets/images/logo.png';
import Mina from './assets/images/mina.png';

import UserDropdown from './components/UserDropdown';
import mixpanel from './mixpanel';
import ReferralCodeModal from './ReferralCodeModal';
import LockedExamModal from './LockedExamModal';
import { FaBars, FaChevronDown } from 'react-icons/fa';
// import AskMinaButton from './components/AskMinaButton.js';
// import AskMinaHome from './AskMinaHome';

function Home() {
    // const navigate = useNavigate();
    const sections = useMemo(() => [
  
        { name: "Grammar", apiSection: "grammar" },
        { name: "Vocabulary", apiSection: "vocabulary" },
        { name: "Reading", apiSection: "reading" },
    ], []);

    // const levels = ["N1", "N2", "N3", "N4", "N5"];

    const [activeSection, setActiveSection] = useState(sections[0].name);
    const [selectedLevel, ] = useState(() => {
        return localStorage.getItem('selectedLevel') || "N3";
    });
    const [sectionData, setSectionData] = useState([]);
    const [, setAverageScore] = useState(0);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [userStatus, setUserStatus] = useState(null);
    const [userData, setUserData] = useState(null);
    const [showReferralModal, setShowReferralModal] = useState(false);
    const [showLockedExamModal, setShowLockedExamModal] = useState(false);
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
    const [categories, setCategories] = useState([]);
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [isSectionDropdownOpen, setIsSectionDropdownOpen] = useState(false);
    // const [showAskMina, setShowAskMina] = useState(false);
    const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 768);
    const navigate = useNavigate();

    const toggleMobileMenu = () => setIsMobileMenuOpen(!isMobileMenuOpen);

    const handleLogout = () => {
        localStorage.removeItem('jwt');
        navigate('/login');
    };

    // const DIAGNOSTIC_EXAMS_API_URL = "https://api.getmina.co/api/v1/panel/diagnostic-exams";
    // const fetchDiagnosticExams = useCallback(async () => {
    //     try {
    //         const jwt = localStorage.getItem('jwt');
    //         const response = await fetch(`${DIAGNOSTIC_EXAMS_API_URL}/${selectedLevel}`, {
    //             headers: { 'Authorization': `Bearer ${jwt}` }
    //         });
    //         const diagnosticExams = await response.json();
    //         console.log("Diagnostic Exams:", diagnosticExams);
    //         navigate(`/StudyPlan`);
    //     } catch (error) {
    //         console.error("Error fetching diagnostic exams:", error);
    //     }
    // }, [navigate, selectedLevel]);

    // const fetchStudyPlanAccess = useCallback(async () => {
    //     try {
    //         const jwt = localStorage.getItem('jwt');
    //         const response = await fetch("https://api.getmina.co/api/v1/panel/study-plan/access", {
    //             method: "GET",
    //             headers: {
    //                 'Authorization': `Bearer ${jwt}`,
    //                 "Content-Type": "application/json"
    //             }
    //         });
    //         const data = await response.json();
            
    //         if (data.access) {
    //             fetchDiagnosticExams();
    //         } else {
    //             alert("Feature coming soon!");
    //         }
    //     } catch (error) {
    //         console.error("Error checking study plan access:", error);
    //         alert("An error occurred. Please try again later.");
    //     }
    // }, [fetchDiagnosticExams]);

    const fetchExams = useCallback(async () => {
        const section = sections.find(s => s.name === activeSection);
        if (!section) return;

        try {
            setLoading(true);
            const jwt = localStorage.getItem('jwt');
            const response = await fetch("https://api.getmina.co/api/v1/panel/get-user-exams", {
                method: "POST",
                headers: {
                    'Authorization': `Bearer ${jwt}`,
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({ level: selectedLevel, section: section.apiSection })
            });
            const data = await response.json();
            console.log("data",data);
            const validData = Array.isArray(data) ? data : [];
            setSectionData(validData);

            const allCategories = new Set(validData.flatMap(exam => {
                if (Array.isArray(exam.categories)) {
                    return exam.categories;
                } else {
                    return [];
                }
            }));
            setCategories(Array.from(allCategories));

            setLoading(false);

            const meResponse = await fetch("https://api.getmina.co/api/v1/auth/me", {
                method: "GET",
                headers: {
                    'Authorization': `Bearer ${jwt}`,
                    "Content-Type": "application/json"
                },
            });

            const meData = await meResponse.json();

            if (meData.msg === "Token has expired") {
                localStorage.removeItem('jwt');
                window.location.reload();
            } else {
                setUserStatus(meData.status);
                setUserData(meData);
                mixpanel.identify(meData.user_id);
            }
        } catch (error) {
            console.error("Error fetching exams:", error);
            setError("Error fetching exams");
            setLoading(false);
        }
    }, [activeSection, selectedLevel, sections]);

    const toggleCategory = (category) => {
        setSelectedCategories(prev => 
            prev.includes(category)
                ? prev.filter(c => c !== category)
                : [...prev, category]
        );
    };

    const filteredExams = useMemo(() => {
        if (selectedCategories.length === 0) return sectionData;
        
        return sectionData.filter(exam => {
            if (!exam) {
                console.warn("Invalid exam object:", exam);
                return false;
            }
            if (!Array.isArray(exam.categories)) {
                return false;
            }
            return selectedCategories.every(category => exam.categories.includes(category));
        });
    }, [sectionData, selectedCategories]);

    const fetchAverageScore = useCallback(async (level, section) => {
        try {
            const jwt = localStorage.getItem('jwt');
            const response = await fetch("https://api.getmina.co/api/v1/panel/get-average-score", {
                method: "POST",
                headers: {
                    'Authorization': `Bearer ${jwt}`,
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({ level, section: section.toLowerCase() })
            });
            const data = await response.json();
    
            const scoreData = data.find(d => d.level === level && d.section === section.toLowerCase());
    
            const newAverageScore = scoreData ? scoreData.average_score : 0;
            setAverageScore(newAverageScore);
        } catch (error) {
            console.error("Error fetching average score:", error);
            setAverageScore(0);
        }
    }, []);

    useEffect(() => {
      
        fetchExams();
        fetchAverageScore(selectedLevel, activeSection);
        console.log("SLECTEDLEVEL",selectedLevel);
    }, [fetchExams, fetchAverageScore, activeSection, selectedLevel]);

    useEffect(() => {
        localStorage.setItem('selectedLevel', selectedLevel);
    }, [selectedLevel]);

    useEffect(() => {
        const handleResize = () => {
            setIsMobileView(window.innerWidth <= 768);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const initiateCheckout = async (referralCode = '') => {
        mixpanel.track('Get full access', {});

        try {
            const jwt = localStorage.getItem('jwt');
            const response = await fetch('https://api.getmina.co/api/v1/payment/create-checkout', {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${jwt}`,
                    'Content-Type': 'application/json',
                },
                body: referralCode ? JSON.stringify({ referral_code: referralCode }) : JSON.stringify({})
            });

            const data = await response.json();

            if (response.ok) {
                const stripe = window.Stripe('pk_live_51PCLezBQK9NeVKEQWkVuPXASDt38HDl8kgGnYWKB9nWxfA8Atp2AeArCagP1UQue3FfktYrYcaISnOoOyOompsVt00Ps2Qs4ay');
                stripe.redirectToCheckout({ sessionId: data.sessionId });
            } else {
                throw new Error(data.error || 'Failed to initiate checkout');
            }
        } catch (error) {
            console.error('Failed to initiate checkout:', error.message);
        }
    };

    function renderExamItem(exam, index) {
        const reviewPath = `/review-exam/${exam.exam_id}`;
        const examPath = `/exam/${exam.exam_id}`;

        // Function to format categories
        const formatCategories = (categories) => {
            if (!categories || categories.length === 0) return '';
            if (categories.length === 1) return categories[0];
            if (categories.length === 2) return categories.join(' and ');
            return categories.join(', ');
        };

        if (exam.isLocked) {
            return (
                <div key={exam.exam_id} className="exam-item">
                    <div className="exam-item-box exam-item-locked">
                        <div className="exam-item-locked-container">
                            <div className="exam-item-locked-icon"></div>
                        </div>
                        <div className="exam-item-info">
                            <div className="exam-title-text">
                                {exam.section ? exam.section.charAt(0).toUpperCase() + exam.section.slice(1) : `Exam ${index + 1}`}
                            </div>
                            <div className="exam-subtitle-text">
                                {formatCategories(exam.categories)}
                            </div>
                        </div>
                    </div>
                </div>
            );
        }

        return (
            <div key={exam.exam_id} className="exam-item">
                <Link
                    to={exam.completed ? reviewPath : examPath}
                    className={`exam-item-box ${exam.completed ? 'exam-item-completed' : 'exam-item-not-completed'}`}
                >
                    {exam.completed && (
                        <div className="exam-item-checkmark-container">
                            <div className="exam-item-checkmark"></div>
                        </div>
                    )}
                    <div className="exam-item-info">
                        <div className="exam-title-text">
                            {exam.section ? exam.section.charAt(0).toUpperCase() + exam.section.slice(1) : `Exam ${index + 1}`}
                        </div>
                        <div className="exam-subtitle-text">
                            {formatCategories(exam.categories)}
                        </div>
                    </div>
                </Link>
            </div>
        );
    }

    // const handleAskMinaClick = () => {
    //     if (isMobileView) {
    //         navigate('/askminahome');
    //     } else {
    //         setShowAskMina(true);
    //     }
    // };

    // const handleAskMinaClose = () => {
    //     setShowAskMina(false);
    // };

    return (
        <div className="study-plan-container">
 <div className="topbar-container">
                <div className="topbar-container-left">
                    {isMobileView ? (
                        <img src={Mina} alt="Mina Logo" className="mina" />
                    ) : (
                        <img src={Logo} alt="InnovateTech Logo" className="logo" />
                    )}

                    <FaBars className="menu-icon" onClick={toggleMobileMenu} />
                    <NavLink to="/" className="topbar-link">Recommended study</NavLink>
                    <NavLink to="/selected_study" className="topbar-link">Selected study</NavLink>
                    {/* <NavLink to="/stats" className="topbar-link">Analysis</NavLink> */}
                </div>
                <div className="topbar-container-right">
                    {userStatus === "inactive" && (
                        <button onClick={() => setShowReferralModal(true)} className="upgrade-button">
                            Get full access
                        </button>
                    )}
                    {userStatus !== "inactive" && userData && <UserDropdown userData={userData} />}
                </div>
            </div>

            {/* Mobile Menu Overlay */}
            <div 
                className={`mobile-menu-overlay ${isMobileMenuOpen ? 'active' : ''}`} 
                onClick={toggleMobileMenu}
            ></div>

            {/* Mobile Menu */}
            <div className={`mobile-menu ${isMobileMenuOpen ? 'active' : ''}`}>
                <div className="mobile-menu-header">
                    <img src={Logo} alt="InnovateTech Logo" className="mobile-menu-logo" />
                </div>
                <div className="mobile-menu-close" onClick={toggleMobileMenu}>&times;</div>
                <div className="mobile-menu-content">
                    <NavLink to="/" className="mobile-menu-item" onClick={toggleMobileMenu}>Recommended study</NavLink>
                    <NavLink to="/selected_study" className="mobile-menu-item" onClick={toggleMobileMenu}>Selected study</NavLink>
                    {/* {userStatus === "inactive" && (
                        <button onClick={() => {
                            setShowReferralModal(true);
                            toggleMobileMenu();
                        }} className="mobile-menu-item">
                            Get full access
                        </button>
                    )} */}
                    <div className="mobile-menu-spacer"></div>
                    <button onClick={() => {
                        handleLogout();
                        toggleMobileMenu();
                    }} className="mobile-menu-logout">
                        Logout
                    </button>
                </div>
            </div>

            <div className="selected-study-bar">
                <div className="selected-study-content">
                    <div className="selected-study-title">Selected study</div>
                    <p className="selected-study-subtitle">Select and learn only the content you want.</p>
                </div>
            </div>

            {/* <div className="home-selectors">
                <div className="level-selector-container">
                    <div className="selector-title">Level</div>
                    <Dropdown
                        options={levels}
                        selected={selectedLevel}
                        onChange={setSelectedLevel}
                    />
                </div>
                <div className="average-score-container">
                    <div className="selector-title">Average Score</div>
                    <div className="average-score">{averageScore}%</div>
                </div>
            </div> */}
            

            <div className="section-selector">
                <div className="section-titles">
                    {sections.map((section, index) => (
                        <div key={index} className={`section-title ${activeSection === section.name ? 'active' : ''}`} onClick={() => setActiveSection(section.name)}>
                            {section.name}
                        </div>
                    ))}
                </div>
            </div>

            <div className="section-dropdown-container">
                <div className="section-dropdown" onClick={() => setIsSectionDropdownOpen(!isSectionDropdownOpen)}>
                    <span>{activeSection}</span>
                    <FaChevronDown className={`dropdown-icon ${isSectionDropdownOpen ? 'open' : ''}`} />
                </div>
                {isSectionDropdownOpen && (
                    <div className="dropdown-menu">
                        {sections.map((section, index) => (
                            <div 
                                key={index} 
                                className={`dropdown-item ${activeSection === section.name ? 'active' : ''}`}
                                onClick={() => {
                                    setActiveSection(section.name);
                                    setIsSectionDropdownOpen(false);
                                }}
                            >
                                {section.name}
                            </div>
                        ))}
                    </div>
                )}
            </div>

            {/* <div className="section-dropdown">
                <SectionDropdown
                    options={sections.map(section => section.name)}
                    selected={activeSection}
                    onChange={setActiveSection}
                />
            </div> */}

            <div className="category-boxes">
                {categories.map(category => (
                    <button
                        key={category}
                        className={`category-box ${selectedCategories.includes(category) ? 'selected' : ''}`}
                        onClick={() => toggleCategory(category)}
                    >
                        {category}
                    </button>
                ))}
            </div>

            {loading ? (
                <div className="loader-container"><BeatLoader color="#3E00EF" /></div>
            ) : error ? (
                <div>{error}</div>
            ) : (
                <div className="exams-list-container">
                    {filteredExams.map((exam, index) => renderExamItem(exam, index))}
                </div>
            )}

            {showReferralModal && (
                <ReferralCodeModal
                    onClose={() => setShowReferralModal(false)}
                    onCheckout={initiateCheckout}
                />
            )}
            {showLockedExamModal && (
                <LockedExamModal
                    onClose={() => setShowLockedExamModal(false)}
                    onGetFullAccess={() => {
                        setShowLockedExamModal(false);
                        initiateCheckout();
                    }}
                />
            )}

            {/* <AskMinaButton onClick={handleAskMinaClick} /> */}
            
            {/* {!isMobileView && showAskMina && (
                <div className="ask-mina-overlay">
                    <AskMinaHome
                        onClose={handleAskMinaClose}
                    />
                </div>
            )} */}
        </div>
    );
}

export default Home;