import React, { useState } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import '../css/personalized_exam.css';
// import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import useMediaQuery from '@mui/material/useMediaQuery';
import cancelIcon from '../assets/icons/cancel.png';

function ReviewQuestion() {
  const navigate = useNavigate();
  const location = useLocation();
  const { exam_id } = useParams();
  const isSmallScreen = useMediaQuery('(max-width: 768px)');
  const [currentContentIndex, setCurrentContentIndex] = useState(location.state?.currentContentIndex || 0);
  const examData = location.state?.examData || [];

  const totalQuestions = examData.length;
  const remainingQuestions = totalQuestions - currentContentIndex;

  const handlePreviousQuestion = () => {
    if (currentContentIndex > 0) {
      setCurrentContentIndex(currentContentIndex - 1);
    }
  };

  const handleNextQuestion = () => {
    if (currentContentIndex < totalQuestions - 1) {
      setCurrentContentIndex(currentContentIndex + 1);
    }
  };

  const handleBackButtonClick = () => {
    navigate(`/review-personalized/${exam_id}`);
  };

  if (examData.length === 0 || !examData[currentContentIndex]) {
    return <div>No content available</div>;
  }

  const { script, question_text, instruction, choices, correct_answer, selected_answer, explanation } = examData[currentContentIndex];

  const formatText = (text) => {
    if (!text || text === '0') return '';

    // First, replace {{NEWLINE}} with actual line breaks
    const textWithLineBreaks = text.replace(/{{NEWLINE}}/g, '\n');

    // Then, split the text and create spans for each part
    return textWithLineBreaks.split(/(?<!\S)(\s{2,})(?!\S)(?!.*\))/g).map((part, index) => (
      <span key={index}>
        {part.trim().length === 0 ? <br /> : part.split('\n').map((line, i) => (
          <React.Fragment key={i}>
            {i > 0 && <br />}
            {line}
          </React.Fragment>
        ))}
      </span>
    ));
  };

  return (
    <div className="Exam">
      <div className={`upper-bar `}>
        <div className="upper-left">
          <div onClick={handleBackButtonClick} aria-label="cancel" className="cancel-button-exam">
            <img src={cancelIcon} alt="Cancel" />
          </div>
          <div className="questions-remaining">
            {remainingQuestions} questions remaining
          </div>
        </div>
      </div>

      <div className="content">
        {isSmallScreen ? (
          <>
            <div className="right-section">
              <div className='question-title'> Question {currentContentIndex + 1}</div>
              {instruction && <p>{formatText(instruction)}</p>}
              <br />
              {script && script !== "0" && (
                <>
                  <div className="passage-box">
                    {formatText(script)}
                  </div>
                </>
              )}
              <br />
              <div className="question-answer">
                <div className="question-text">{formatText(question_text)}</div>
                <div className="answer-section">
                  {choices.map((choice, index) => (
                    <div
                      key={index}
                      className={`answer-option 
                        ${selected_answer === choice ? 'selected' : ''} 
                        ${choice === correct_answer ? 'correct' : (selected_answer === choice ? 'incorrect' : '')}`}
                    >
                      {choice}
                    </div>
                  ))}
                </div>
                {explanation && (
                  <div className="explanation-box">
                    <strong>Explanation:</strong> {formatText(explanation)}
                  </div>
                )}
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="personalized-page">
              <div className='question-title'> Question {currentContentIndex + 1}</div>
              {instruction && <p>{formatText(instruction)}</p>}
              {script && script !== "0" ? (
                <div className="passage-box">
                  {formatText(script)}
                </div>
              ) : (
                <hr className="question-divider" />
              )}
              <div className="question-answer">
                <div className="question-text">{formatText(question_text)}</div>
                <div className="answer-section">
                  {choices.map((choice, index) => (
                    <div
                      key={index}
                      className={`answer-option 
                        ${selected_answer === choice ? 'selected' : ''} 
                        ${choice === correct_answer ? 'correct' : (selected_answer === choice ? 'incorrect' : '')}`}
                    >
                      {choice}
                    </div>
                  ))}
                </div>
                {explanation && (
                  <div className="explanation-box">
                    <strong>Explanation:</strong> {formatText(explanation)}
                  </div>
                )}
              </div>
            </div>
          </>
        )}
      </div>

      <div className={`lower-bar`}>
        <div className="lower-left"></div>
        <div className="lower-right">
          <button className="previous-button" onClick={handlePreviousQuestion}>Previous</button>
          <button className="reveal-answer-button" onClick={handleNextQuestion}>Next</button>
        </div>
      </div>
    </div>
  );
}

export default ReviewQuestion;