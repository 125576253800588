import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import '../css/personalized_exam.css';
import BeatLoader from 'react-spinners/BeatLoader';
// import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import mixpanel from '../mixpanel';
// import useMediaQuery from '@mui/material/useMediaQuery';
import cancelIcon from '../assets/icons/cancel.png';

const GET_PERSONALIZED_EXAM_API_URL = "https://api.getmina.co/api/v1/panel/personalized-exam";
const SUBMIT_PERSONALIZED_EXAM_API_URL = "https://api.getmina.co/api/v1/panel/personalized-exam/submit";
const REPORT_ISSUE_API_URL = "https://api.getmina.co/api/v1/panel/report-issue";

function PersonalizedExam() {
  const navigate = useNavigate();
  const location = useLocation();
  const { exam_id } = useParams();
  // const isSmallScreen = useMediaQuery('(max-width: 768px)');
  const [responses, setResponses] = useState([]);
  const [content, setContent] = useState([]);
  const [currentContentIndex, setCurrentContentIndex] = useState(location.state?.currentContentIndex || 0);
  const [userSelectedAnswer, setUserSelectedAnswer] = useState(null);
  const [revealAnswer, setRevealAnswer] = useState(false);
  const [isAnswerRevealed, setIsAnswerRevealed] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [hintReceived, setHintReceived] = useState(false);
  const [showReportModal, setShowReportModal] = useState(false);
  const [selectedIssue, setSelectedIssue] = useState('');
  const [otherIssueText, setOtherIssueText] = useState('');
  const [questionStartTime, setQuestionStartTime] = useState(null);
  const [timerMinutes, setTimerMinutes] = useState(0);
  const [timerSeconds, setTimerSeconds] = useState(0);

  const examIdNumber = Number(exam_id);
  const hasFetchedQuestions = useRef(false);

  const fetchQuestions = useCallback(async () => {
    try {
      setLoading(true);
      const jwt = localStorage.getItem('jwt');
      const response = await fetch(`${GET_PERSONALIZED_EXAM_API_URL}/${examIdNumber}`, {
        method: "GET",
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${jwt}`
        },
      });

      const data = await response.json();
      console.log("API response:", data);

      if (data && data.responses && Array.isArray(data.responses)) {
        setContent(data.responses.map(question => ({ ...question, selectedAnswer: null, isAnswered: false })));
      } else {
        console.error("Unexpected data structure:", data);
        setError("Unexpected data structure received from the server");
      }
      setLoading(false);
    } catch (error) {
      console.error("Error fetching questions:", error);
      setError("Error fetching questions");
      setLoading(false);
    }
  }, [examIdNumber]);

  useEffect(() => {
    if (!hasFetchedQuestions.current) {
      fetchQuestions();
      hasFetchedQuestions.current = true;
    }
    // Set the start time when a new question is displayed
    setQuestionStartTime(Date.now());
  }, [fetchQuestions, currentContentIndex]);

  useEffect(() => {
    const timerInterval = setInterval(() => {
      setTimerSeconds(prev => {
        if (prev === 59) {
          setTimerMinutes(min => min + 1);
          return 0;
        }
        return prev + 1;
      });
    }, 1000);

    return () => clearInterval(timerInterval);
  }, []);

  const totalQuestions = content.length;
  const remainingQuestions = totalQuestions - currentContentIndex;

  const handleSubmit = async () => {
    if (currentContentIndex === content.length - 1) {
      const requestBody = {
        exam_id: exam_id,
        responses: responses,
      };
      console.log("requestBody", requestBody);
      console.log("requestBodyresponses", requestBody.responses);

      try {
        const jwt = localStorage.getItem('jwt');
        const response = await fetch(SUBMIT_PERSONALIZED_EXAM_API_URL, {
          method: "POST",
          headers: {
            'Authorization': `Bearer ${jwt}`,
            "Content-Type": "application/json"
          },
          body: JSON.stringify(requestBody)
        });

        if (!response.ok) {
          const errorText = await response.text();
          throw new Error(`Failed to submit exam: ${response.status} ${errorText}`);
        }

        alert("Exam submitted successfully!");
        mixpanel.track('Exam Completed', {
          exam_id: exam_id,
         exam_type: "personalized_exam"
        });
        navigate(`/review-personalized/${exam_id}`);
      } catch (error) {
        console.error("Failed to submit exam:", error.message);
      }
    } else {
      setCurrentContentIndex(currentContentIndex + 1);
      setIsAnswerRevealed(false);
      setHintReceived(false);
     
    }
  };

  const handleAnswerSelection = (index) => {
    if (!isAnswerRevealed) {
      const timeTaken = Math.round((Date.now() - questionStartTime) / 1000); // Calculate time taken in seconds
      setUserSelectedAnswer(index);
      setRevealAnswer(false);
  
      const updatedContent = content.map((question, idx) =>
        idx === currentContentIndex ? { ...question, selectedAnswer: index } : question
      );
      setContent(updatedContent);
      const questionId = content[currentContentIndex]?.question_id;
      const selectedAnswer = index === choices.length ? "I'm not sure" : choices[index];
  
      const newResponses = responses.filter(response => response.question_id !== questionId);
      newResponses.push({ 
        question_id: questionId, 
        selected_answer: selectedAnswer,
        time_taken: timeTaken
      });
      console.log("newResponses", newResponses);
      setResponses(newResponses);

      // Reset the start time for the next question
      setQuestionStartTime(Date.now());
    }
  };

  const handleRevealAnswer = () => {
    if (userSelectedAnswer === null) {
      return;
    }

    if (isAnswerRevealed) {
      const nextContentIndex = currentContentIndex + 1;
      if (nextContentIndex < content.length) {
        setCurrentContentIndex(nextContentIndex);
        setUserSelectedAnswer(content[nextContentIndex]?.selectedAnswer);
        setRevealAnswer(content[nextContentIndex]?.isAnswered);
        setIsAnswerRevealed(content[nextContentIndex]?.isAnswered);
        setHintReceived(false);
       
      }
      if (isAnswerRevealed && currentContentIndex === content.length - 1) {
        handleSubmit();
      }
    } else {
      setUserSelectedAnswer(content[currentContentIndex]?.selectedAnswer);
      setRevealAnswer(true);
      setIsAnswerRevealed(true);

      const updatedContent = content.map((question, idx) =>
        idx === currentContentIndex ? { ...question, isAnswered: true } : question
      );
      setContent(updatedContent);
      mixpanel.track('Reveal Answer', {
        question: content[currentContentIndex]?.question,
        question_id: content[currentContentIndex]?.question_id,
        question_number: currentContentIndex + 1,
        selected_answer: content[currentContentIndex].choices[userSelectedAnswer],
        // time_taken: content[currentContentIndex].choices[time_taken],
        correct_answer: content[currentContentIndex]?.answer,
        level: localStorage.getItem('selectedLevel'),
      });
    }
  };

  const handlePreviousQuestion = () => {
    if (currentContentIndex > 0) {
      const previousContentIndex = currentContentIndex - 1;
      setCurrentContentIndex(previousContentIndex);
      setUserSelectedAnswer(content[previousContentIndex]?.selectedAnswer);
      setRevealAnswer(true);
      setIsAnswerRevealed(true);
      setHintReceived(false);
    }
  };

  const handleBackButtonClick = () => {
    mixpanel.track('Cancel Personalized Exam', {
      exam_id: exam_id,
      question_id: content[currentContentIndex]?.question_id,
      question_number: currentContentIndex + 1,
    });
    navigate('/');
  };

  const formatText = (text) => {
    if (!text || text === '0') return '';

    // First, replace {{NEWLINE}} with actual line breaks
    const textWithLineBreaks = text.replace(/{{NEWLINE}}/g, '\n');

    // Then, split the text and create spans for each part
    return textWithLineBreaks.split(/(?<!\S)(\s{2,})(?!\S)(?!.*\))/g).map((part, index) => (
      <span key={index}>
        {part.trim().length === 0 ? <br /> : part.split('\n').map((line, i) => (
          <React.Fragment key={i}>
            {i > 0 && <br />}
            {line}
          </React.Fragment>
        ))}
      </span>
    ));
  };

  const handleReportIssue = () => {
    setShowReportModal(true);
  };

  const handleSubmitIssue = async () => {
    try {
      const jwt = localStorage.getItem('jwt');
      const issueMessage = selectedIssue === "Other" ? otherIssueText : selectedIssue;
      const response = await fetch(REPORT_ISSUE_API_URL, {
        method: "POST",
        headers: {
          'Authorization': `Bearer ${jwt}`,
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          question_id: content[currentContentIndex]?.question_id,
          issue_message: issueMessage,
        })
      });

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`Failed to submit issue: ${response.status} ${errorText}`);
      }

      alert("Issue reported successfully!");
      setShowReportModal(false);
      setSelectedIssue('');
      setOtherIssueText('');
    } catch (error) {
      console.error("Failed to submit issue:", error.message);
    }
  };

  const closeModal = (e) => {
    if (e.target.className === "report-issue-overlay") {
      setShowReportModal(false);
    }
  };

  if (loading) return <div className="loader-container"><BeatLoader color="#3E00EF" /></div>;

  if (error) {
    return <div>{error}</div>;
  }

  if (content.length === 0 || !content[currentContentIndex]) {
    return <div>No content available</div>;
  }

  // const questionNumber = currentContentIndex + 1;
  const { script, question, instruction, choices, correct_answer, hint, explanation, selectedAnswer, isAnswered } = content[currentContentIndex];


  return (
    <div className="Exam">
      <>
        <div className={`upper-bar `}>
          <div className="upper-left">
            <div onClick={handleBackButtonClick} aria-label="cancel" className="cancel-button-exam">
              <img src={cancelIcon} alt="Cancel" />
            </div>
            <div className="questions-remaining">
              {remainingQuestions} questions remaining
            </div>
          </div>
          <div className="upper-right">
            <div className="timer">
              {timerMinutes}:{timerSeconds < 10 ? `0${timerSeconds}` : timerSeconds}
            </div>
          </div>
        </div>

        <div className="content">
          {script && script !== "0" ? (
            <div className="two-column-layout">
              <div className="script-column">
                <div className="question-number">Question {currentContentIndex + 1}</div>
                {formatText(script)}
              </div>
              <div className="questions-column">
                {instruction && (
                  <div className="instruction-box">
                    <p className="instruction">{formatText(instruction)}</p>
                  </div>
                )}
                <div className="question-box">
                  <div className="question-text">{formatText(question)}</div>
                  <div className="answer-section">
                    {choices.map((choice, index) => (
                      <div
                        key={index}
                        className={`answer-option 
                          ${selectedAnswer === index ? 'selected' : ''} 
                          ${revealAnswer ? (choice === correct_answer ? 'correct' : (selectedAnswer === index ? 'incorrect' : '')) : ''}`}
                        onClick={() => !isAnswered && handleAnswerSelection(index)}
                      >
                        {choice}
                      </div>
                    ))}
                    <div
                      className={`answer-option 
                        ${selectedAnswer === choices.length ? 'selected' : ''}
                        ${revealAnswer && selectedAnswer === choices.length ? 'incorrect' : ''}`}
                      onClick={() => !isAnswered && handleAnswerSelection(choices.length)}
                    >
                      I'm not sure
                    </div>
                  </div>
                  {hintReceived && hint && (
                    <div className="hint-box">
                      <strong>Hint:</strong> {hint}
                    </div>
                  )}
                  {isAnswerRevealed && explanation && (
                    <div className="explanation-box">
                      <strong>Explanation:</strong> {explanation}
                    </div>
                  )}
                </div>
              </div>
            </div>
          ) : (
            <div className="left-section">
              <div className='question-title'>Question {currentContentIndex + 1}</div>
              {instruction && (
                <p className="instruction">{formatText(instruction)}</p>
              )}
              <hr className="question-divider" />
              <div className="question-answer">
                <div className="question-text">{formatText(question)}</div>
                <div className="answer-section">
                  {choices.map((choice, index) => (
                    <div
                      key={index}
                      className={`answer-option 
                        ${selectedAnswer === index ? 'selected' : ''} 
                        ${revealAnswer ? (choice === correct_answer ? 'correct' : (selectedAnswer === index ? 'incorrect' : '')) : ''}`}
                      onClick={() => !isAnswered && handleAnswerSelection(index)}
                    >
                      {choice}
                    </div>
                  ))}
                  <div
                    className={`answer-option 
                      ${selectedAnswer === choices.length ? 'selected' : ''}
                      ${revealAnswer && selectedAnswer === choices.length ? 'incorrect' : ''}`}
                    onClick={() => !isAnswered && handleAnswerSelection(choices.length)}
                  >
                    I'm not sure
                  </div>
                </div>
                {hintReceived && hint && (
                  <div className="hint-box">
                    <strong>Hint:</strong> {hint}
                  </div>
                )}
                {isAnswerRevealed && explanation && (
                  <div className="explanation-box">
                    <strong>Explanation:</strong> {explanation}
                  </div>
                )}
              </div>
            </div>
          )}
        </div>

        <div className={`lower-bar`}>
          {/* {isSmallScreen && (
            <button className="report-issue-button" onClick={handleReportIssue}>Report Issue</button>
          )} */}
             <button className="report-issue-button" onClick={handleReportIssue}>Report Issue</button>
             <div className="lower-left"></div>

          <div className="lower-right">
            <button className="previous-button" onClick={handlePreviousQuestion}>Previous</button>
            <button className="reveal-answer-button" onClick={handleRevealAnswer} disabled={userSelectedAnswer === null}>
              {isAnswerRevealed ? currentContentIndex === content.length - 1 ? "Finish" : "Next" : "Check Answer"}
            </button>
          </div>
        </div>

      
      </>
 
      {showReportModal && (
        <div className="report-issue-overlay" onClick={closeModal}>
          <div className="report-issue-modal">
            <h2>Report Issue</h2>
            <hr />
            <p>What's wrong?</p>
            <div className="issue-option">
              <label>
                <input
                  type="radio"
                  name="issue"
                  value="None of theoptions is correct"
                  checked={selectedIssue === "None of the options is correct"}
                  onChange={(e) => setSelectedIssue(e.target.value)}
                  />
                  None of the options is correct
                  </label>
                  </div>
                  <div className="issue-option">
                  <label>
                  <input
                  type="radio"
                  name="issue"
                  value="Question has incorrect or missing data"
                  checked={selectedIssue === "Question has incorrect or missing data"}
                  onChange={(e) => setSelectedIssue(e.target.value)}
                  />
                  Question has incorrect or missing data
                  </label>
                  </div>
                  <div className="issue-option">
                  <label>
                  <input
                  type="radio"
                  name="issue"
                  value="Questions/Options/Infographics are not visible properly or missing"
                  checked={selectedIssue === "Questions/Options/Infographics are not visible properly or missing"}
                  onChange={(e) => setSelectedIssue(e.target.value)}
                  />
                  Questions/Options/Infographics are not visible properly or missing
                  </label>
                  </div>
                  <div className="issue-option">
                  <label>
                  <input
                  type="radio"
                  name="issue"
                  value="Question or option has a typo"
                  checked={selectedIssue === "Question or option has a typo"}
                  onChange={(e) => setSelectedIssue(e.target.value)}
                  />
                  Question or option has a typo
                  </label>
                  </div>
                  <div className="issue-option">
                  <label>
                  <input
                  type="radio"
                  name="issue"
                  value="The question is confusing or unclear"
                  checked={selectedIssue === "The question is confusing or unclear"}
                  onChange={(e) => setSelectedIssue(e.target.value)}
                  />
                  The question is confusing or unclear
                  </label>
                  </div>
                  <div className="issue-option">
                  <label>
                  <input
                  type="radio"
                  name="issue"
                  value="Other"
                  checked={selectedIssue === "Other"}
                  onChange={(e) => setSelectedIssue(e.target.value)}
                  />
                  Other
                  </label>
                  </div>
                  {selectedIssue === "Other" && (
                    <textarea
                      className="other-issue-input"
                      value={otherIssueText}
                      onChange={(e) => setOtherIssueText(e.target.value)}
                      placeholder="Please describe the issue"
                      rows={3}
                    />
                  )}
                  <hr />
                  <button 
                    className="submit-issue-button" 
                    onClick={handleSubmitIssue}
                    disabled={selectedIssue === "Other" && otherIssueText.trim() === ""}
                  >
                    Submit Issue
                  </button>
                  </div>
                  </div>
                  )}
                  </div>
                  );
                  }
                  export default PersonalizedExam;