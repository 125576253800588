import React, { useState } from 'react';
import mixpanel from './mixpanel';
import './css/Login.css';

// import { useNavigate } from 'react-router-dom';
import {
  Box,
  TextField,
  Button,
  Typography,
  Container,
  CssBaseline,
  Alert,
} from '@mui/material';
import { styled } from '@mui/system';
import Logo from './assets/images/logo.png';
import BeatLoader from 'react-spinners/BeatLoader';

const StyledContainer = styled(Container)({
  marginTop: '100px',
  backgroundColor: 'rgb(255, 255, 255)',
  padding: '40px',
  borderRadius: '20px',
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  textAlign: 'center',
  position: 'relative',
});

const StyledForm = styled('form')({
  width: '100%',
  marginTop: '1rem',
});

const StyledTextField = styled(TextField)({
  width: '100%',
  marginBottom: '20px',
  '& .MuiOutlinedInput-root': {
    borderRadius: '10px',
    '& fieldset': {
      borderColor: '#e0e0e0',
    },
    '&:hover fieldset': {
      borderColor: '#bdbdbd',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#757575',
    },
  },
  '& .MuiInputBase-input': {
    padding: '14px',
  },
});

const FieldTitle = styled(Typography)({
  textAlign: 'left',
  marginBottom: '5px',
  fontWeight: 'bold',
});

const StyledButton = styled(Button)({
  marginTop: '1rem',
  marginBottom: '1rem',
  backgroundColor: '#3E00EF',
  color: 'white',
  borderRadius: '5px',
  padding: '10px 20px',
  '&:hover': {
    backgroundColor: '#272499',
  },
  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
  '&.Mui-disabled': {
    backgroundColor: '#c0c0c0',
    color: '#fff',
  },
});

export default function EmailVerification() {
  // const navigate = useNavigate();
  const [otp, setOtp] = useState('');
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!otp) {
      setErrorMessage('OTP is required.');
      return;
    }

    setLoading(true);
    setErrorMessage('');

    const jwt = localStorage.getItem('jwt');

    try {
      const response = await fetch('https://api.getmina.co/api/v1/auth/verify-email', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${jwt}`
        },
        body: JSON.stringify({ otp })
      });

      const responseData = await response.json();
      setLoading(false);

      if (response.ok) {
        // console.log('Email verified successfully:', verificationData);

        const meResponse = await fetch("https://api.getmina.co/api/v1/auth/me", {
          method: "GET",
          headers: {
            'Authorization': `Bearer ${jwt}`,
            "Content-Type": "application/json"
          },
        });
        const meData = await meResponse.json();
        console.log("RESP", meData);

        // Ensure JWT is stored and user is identified
        console.log("THE JWT", jwt);
        localStorage.setItem('jwt', jwt);

        mixpanel.identify(meData.user_id);
        mixpanel.people.set({
          $name: `${meData.FirstName} ${meData.LastName}`, // Assuming these fields are returned
          $email: meData.email,  // Mixpanel's special property for email
          status: meData.status,
        });

        mixpanel.track('Register', {
          email: meData.email,
        });
      


        window.location.href = '/jlpt-experience';
      }else {
        setErrorMessage(responseData.message || 'Verification failed');
      }
    } catch (error) {
      setLoading(false);
      setErrorMessage('Error during email verification: ' + error.message);
    }
  };

  return (
    <>
      <Box className="logo-container-box" sx={{ position: 'relative', top: '0px',  }}>
        <img src={Logo} alt="Mina Logo" style={{ height: '50px' }} />
      </Box>
      <StyledContainer component="main" maxWidth="xs">
        <CssBaseline />
        <Box sx={{ width: '100%' }}>
          <Typography component="h1" variant="h5" sx={{ position: 'absolute', top: '20px', left: '20px', margin: 0 }}>
            Verify Email
          </Typography>
          <br></br>
          {errorMessage && (
            <Alert severity="error" sx={{ width: '100%', marginBottom: 2 }}>
              {errorMessage}
            </Alert>
          )}
          <StyledForm onSubmit={handleSubmit}>
            <FieldTitle>Verification Code E-mail</FieldTitle>
            <StyledTextField
              required
              fullWidth
              id="otp"
              name="otp"
              autoComplete="off"
              autoFocus
              value={otp}
              onChange={(e) => setOtp(e.target.value)}
              placeholder="Enter Verification Code E-mail"
            />
            <StyledButton
              type="submit"
              fullWidth
              variant="contained"
              disabled={loading}
            >
              {loading ? <BeatLoader color="#fff" size={10} /> : 'Verify Email'}
            </StyledButton>
          </StyledForm>
        </Box>
      </StyledContainer>
    </>
  );
}




